:root{
  --classic-fill:white;
  --classic-prompt:black;
  --new-fill: black;
  --new-prompt: white;

  --bg:#1a1a1a;
  --fill:#1f1f1f;
  --fillText:#E7E7E7;
  --fillBorder:#444444;
  --prompt:#080808;
  --promptText:#E7E7E7;
  --disabled:#333333;
  --highlight:#E8912C;
  --secondaryText:#444444;


  background-color: var(--bg);
  --cardHeight:21.875rem;
  --cardWidth:15.625rem;
  overscroll-behavior: none;
  /* overflow-x: hidden; */
  
  
  

}

.App{
 
  color:var(--fillText);
  /* background-color: var(--bg); */

  width:100vw;
  height:100vh;
  /* background-color: var(--bg); */
}


.bg{
  width: 100vw;
  height: 100vh;
  z-index: -100;
  position: absolute;
  background-color: var(--bg);
  touch-action:none;
}

body {
  margin: 0;
  font-family: 'Arimo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow:hidden; */
  /* overflow-x: hidden; */
  background-color: var(--fill);
  touch-action: pan-y;
  /* overscroll-behavior: none; */
  background-color: var(--bg);
  position:relative;

}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.homepageHeaderItem {
  cursor: pointer;
}

.homepageHeaderItem > a, .homepageHeaderItem > svg  {
  color:var(--fillBorder);
  fill:var(--fillBorder);
  text-decoration: none;
}
.homepageHeaderItem:hover > a, .homepageHeaderItem:hover > svg{
  color:#707070;
  fill:#707070;
}

.homepageFill, .homepagePrompt{
  /* width:250px;
  height:350px; */
  width:var(--cardWidth);
  height:var(--cardHeight);
  background-color: var(--fill);
  border-radius: 10px;
  box-shadow: 0px 0px 0px 2px var(--fillBorder);
}

.homepagePrompt{
  background-color: var(--prompt);
  box-shadow: 0px 0px 0px 2px #272727;
  
}


.colorGrey{
  color:var(--disabled);
}
.backgroundColorGrey{
  background-color:var(--disabled);
}

p {
  margin:0
}

.homepageButton {
  all:unset;
  background-color:var(--fill);
  border:2px solid var(--fillBorder);
  border-radius:10px;
  font-size:26px;
  font-weight:bold;
  padding:10px 20px;
  cursor:pointer;
}

.fmpHeader-master--div{
  /* background-color: white; */
  background-color: var(--fill);
  width: 100vw;
  height: 4rem;
  position: absolute;

  top:0;


  /* border-bottom: solid 2px; */
  /* box-shadow: 0 0 20px 0px rgb(0 0 0 / 10%); */
  z-index: 1000;
  transition: box-shadow 1000ms cubic-bezier(0.42, 0, 0.1, 1);
}

.header-divParent-left{
  width:25vw;
  height:4rem;
  display: flex;
  align-items: center;
  /* background-color: rgba(255,0,0,0.2); */
}
.header-divParent-middle{
  width:50vw;
  height:4rem;
  display:flex;
  align-items: center;
  justify-content: center;
  /* margin-left: auto;
  margin-right: auto; */
  /* background-color: rgba(0,255,0,0.2); */
}
.header-divParent-right{
  width:25vw;
  height:4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* background-color: rgba(0,0,255,0.2); */
}

.headerButtonHover {
  background-color: transparent;
}
.headerButtonHover:hover {
  /* background-color: rgba(0, 0, 0, 0.2); */
  background-color: rgba(0, 0, 0, 0.2);
}
/* .headerButtonHover:active {
  background-color: var(--disabled);
} */

.fmpHeader-roomIdLink{
  /* background-color: red; */
  margin: 0;
  /* padding: 0.5rem; */
  border-radius: 0.4rem;
  cursor:pointer;
}
/* .fmpHeader-roomIdLink:hover{
  background-color: rgba(0, 0, 0, 0.2);
}
.fmpHeader-roomIdLink:active{
  background-color: rgba(0, 0, 0, 0.4);
} */

.fmpHeader-roomIdLink-tooltip{
  position: fixed;
  /* width: 100%; */
  /* background-color: red; */
  /* background-color: white; */
  background-color: var(--fill);
  box-shadow: 0 0 0 2px var(--prompt);
  border-radius: 0.4rem;
  padding: 0.3rem 0;
  /* margin-top: 0.8rem; */
  width: 9rem;
  transform: translate(calc(-50% + 3rem),14px);
  /* transition: opacity 50ms ease-in-out; */

}

.fmpHeader-roomIdLink-tooltip-triangle{
  position: fixed;
  /* background-color: red; */
  z-index: 2;
  transform: translate(calc(-50% + 3rem),3px);
  width: 0px;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--prompt);
  /* transition: opacity 50ms ease-in-out; */
}
.fmpHeader-roomIdLink-tooltip-triangleInner{
  position: fixed;
  /* background-color: red; */
  z-index: 3;
  transform: translate(calc(-50% + 3rem),6px);
  width: 0px;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--fill);
  /* transition: opacity 50ms ease-in-out; */
}

.fmpHeader-toggleCIH-master--div{
  /* position: absolute; */
  width: 4rem;
  height: 1.5rem;
  cursor: pointer;
  /* background-color: rgba(255, 0, 0, 0.2); */
  /* transform: translate(calc((100vw - 4rem) - 2rem)); */
  padding: 0.5rem 0px;
  border-radius: 0.4rem;
}

/* .fmpHeader-toggleCIH-master--div:hover{
  background-color: rgba(0, 0, 0, 0.2);
}
.fmpHeader-toggleCIH-master--div:active{
  background-color: rgba(0, 0, 0, 0.4);
} */


.fmpHomepageGreeting-master--div{
  font-size: 2rem;
  /* font-weight: bold; */
  padding-top:15vh;
}
.fmpHomepageGreeting-inputContainer--div {
  padding:0.5rem 0.7rem;
  background-color:var(--fill);
  border-radius:10px;
  color:#666666;
  cursor:pointer;
  transition: width 200ms cubic-bezier(0.01, 0.74, 0.43, 0.99);
  display:flex;
  justify-content:center;
  align-items:center;
  box-shadow: 0px 0px 0px 2px #272727;
}
.fmpHomepageGreeting-inputContainer--div:hover, .fmpHomepageGreeting-inputContainer--div:focus-within{
  box-shadow: 0px 0px 0px 2px var(--disabled);
}

.fmpHomepageGreeting-inputContainer--div > input{
  all: unset;
  
}
.fmpHomepageGreeting-master--div > input{
  all: unset;
  /* font-size: 2rem;
  font-weight: bold;
  border-bottom: 2px var(--fillText) solid;
  width:20rem; */

}

.fmpHomepageJoinMatch--input{
  all: unset;
  font-size: 2rem;
  font-weight: bold;
  border-bottom: 2px var(--promptText) solid;
  width:8rem;
  cursor:text;
  /* margin-top:0.5rem; */
}

.fmpHomepageJoinMatch--input::placeholder{
  color:var(--disabled);
}

.FMP-homepage-card-master--div > div > input::placeholder{
  opacity:0;
  transition: opacity 200ms ease-in-out;
}
.FMP-homepage-card-master--div:hover > div > input::placeholder{
  opacity:1;
  transition: opacity 200ms ease-in-out;
}
.fmpHomepageJoinMatch--input:hover + div{
  background-color:red;
}




.fmpRoom-prematchDisplay-master--div{
  display: flex;
  justify-content: center;
  position: absolute;
  width:100vw;
  overflow:hidden;
}
.fmpRoom-prematchDisplay--div{
  /* display: flex;
  justify-content: center; */
  /* width:80%;
  max-width: 60rem; */

  width:100%;
  height:100vh;
  /* min-height:100vh; */
  max-height: -webkit-fill-available;
}




.fmpRoom-settingsControls-master--div{
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--prompt);
  color:var(--promptText);
  fill:var(--promptText);
  align-items: center;
  /* width:50%; */
}

.fmpRoom-settingsControls-container--div{
  width:70%;
  max-width: 30rem;
}

.fmpRoom-settingsControls--div{
  display: inherit;
  /* justify-content: center; */
  /* align-items: center; */
  /* background-color: rgba(0, 0, 255, 0.164); */
  /* background-color:black;
  color:white; */
  
  flex-direction: column;
  row-gap: 2rem;
  /* width: 100%; */
  font-size:1.5rem;
}
.fmpRoom-playerList-master--div{
  width: 100%;
  /* background-color: var(--fill); */
}

.fmpRoom-playerList--div{
  padding-top:2rem;
  font-size:1.5rem;
}


.fmpButton{
  font-weight: 700;
  all: unset;
  border-radius: 5px;
  /* border: 3px solid; */
  height: 2rem;
  min-width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}
/* .fmpButton:hover{
  background-color:rgba(0, 0, 0, 0.1)
} */


.fmpRoom-settingsControls-setting-master--div{
  display: flex;
  align-items: center;
  column-gap: 2rem;
  justify-content: space-between;
  user-select: none;
  /* width: 50%;
  max-width: 500px; */
}

.fmpRoom-settingsControls-counter--div{
  display: flex;
  flex-direction: row;
  width: 8rem;
  height: 7rem;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  
}

/* .fmpRoom-settingsControls-counter--div svg{
  height:2rem;
  cursor:pointer;
}  */

.svgArrow, .greySvgArrow{
  height:2rem;
  cursor:pointer;
}

.greySvgArrow{
  fill:var(--disabled);
  cursor:default;
}



















.FMP-master--div{
  font-family: 'Arimo', sans-serif;
  width: 100vw;
  height: 100vh;
  /* max-height: -webkit-fill-available;
  max-height:stretch; */
  /* background-color: rgb(243, 243, 243); */
  /* background-color: white; */
  overflow: hidden;
  position:absolute;
}

.FMP-card-master--div{
  /* font-family:'Helvetica Neue'; */
  color:var(--fillText);
  font-family: 'Arimo', sans-serif;
  font-size: 20px;
  font-weight: 600;
  position:absolute;
  width: 250px;
  height:350px;
  background-color: var(--fill);
  /* background-color:rgb(245, 245, 245); */
  border-radius: 10px;
  -webkit-border-radius: 10px;
  /* outline: 2px solid; */
  cursor: pointer;
  -webkit-font-smoothing: auto;

  top:0;
  
  /* will-change: top; */
  /* transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1); */
  
}


.FMP-homepage-card-master--div{
  /* font-family:'Helvetica Neue'; */
  color:var(--fillText);
  font-family: 'Arimo', sans-serif;
  font-size: 20px;
  font-weight: 600;
  position:absolute;
  width: 250px;
  height:350px;
  background-color: var(--fill);
  /* background-color:rgb(245, 245, 245); */
  border-radius: 10px;
  -webkit-border-radius: 10px;
  /* outline: 2px solid; */
  cursor: pointer;
  -webkit-font-smoothing: auto;

  /* top:0; */
  
  /* will-change: top; */
  /* transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1); */
  
}



.FMP-prompt-card{
  /* background-color:rgb(12, 12, 12); */
  background-color: var(--prompt);
  color: var(--promptText);
  outline:none;
  cursor:default;
}



.defaultCursor{
  cursor:default;
}




.FMP-card-textbox--div{
  margin: 20px;
  text-align: left;
  pointer-events: none;
  overflow-wrap: break-word;
}

.temp{
  position:absolute;
  /* background-color:rgba(0, 0, 255, 0.089); */
}


.FMP-roundInfo--div{
  margin:0;
  /* margin-top:1rem; */
  margin-top:30px;
  font-weight: 700;
  position: relative;
  z-index:100;
  pointer-events: none;
  
}
.FMP-roundInfo--div>h1{
  margin:0;
  font-size: 4rem;
  /* line-height: 0.5rem; */
}
.FMP-roundInfo--div>h3{
  margin:0;
  /* font-size: 4rem; */
  /* line-height: 0.5rem; */
}

/* transform-style: preserve-3d; transform: rotate(21.3792deg); */



.fmpResults-playerPoints-master--div{
  /* display: flex;
  justify-content: center;
  column-gap: 2rem; */
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 175px;
  justify-content: center;
  align-items: center;
}

.fmpResults-playerPoints-playerName--div{

  font-size: 1.3rem;
  font-weight: 600;
}

.fmpResults-playerPoints-playerPoints--div{

  font-size: 1.3rem;
  font-weight: 600;
}


.fmpResults-bottomOptionButtons-master--div{
  position:fixed;
  bottom:0;
  width:100%;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  column-gap: 1rem;
}


.fmpButtons{
  all: unset;
  border-radius: 5px;
  border: 2px solid;
  /* height: 2rem;
  min-width: 2rem; */
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  font-weight: 700;
}

.fmpButtonsDisabled{
  color:grey;
  cursor: default;
}


.settingsPanel-master{
  background-color: var(--prompt);
  height: auto;
  width: 410px;
  margin: 30px;
  position:absolute;
  top:0;
  bottom:0;
  border-radius: 10px;
  overflow: hidden;
}
.settingsPanel-settings::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.settingsPanel-content{
  padding:30px;
  color: var(--promptText);
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  height: calc(100vh - 120px);
  /* background-color: var(--prompt);
  height:100%; */
}

.settingsPanel-header {
  font-size: 30px;
  margin: 0px;
  text-align: left;
}

.settingsPanel-settings{
  display: flex;
  flex-direction: column;
  row-gap:10px;
  min-height:0;
}

.settingsPanel-settingsSection {
  display: flex;
  flex-direction: column;
  row-gap:10px;
  padding-bottom:20px;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.settingsPanel-settingsSection::-webkit-scrollbar {
  display: none; /* for Chrome, Safari and Opera */
}

.settingsPanel-subHeader {
  font-size: 1rem;
  margin: 0px;
  color: #808080;
  text-align: left;
}

.settingsPanel-optionRow{
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
}

.settingsPanel-optionRow-Button, .settingsPanel-optionRow-ActionButton {
  all:unset;
  width:100%;
  padding:10px 0px;
  border-radius: 10px;
  border: 2px solid var(--bg);
  color: #808080;
  font-weight: bold;
  font-size: 1.25rem;
  cursor: pointer;
}



.settingsPanel-optionRow-ActionButton {
  cursor: pointer;
  background-color: var(--bg);
  color: var(--promptText);
  border-color: #404040;
}

.settingsPanel-optionRow-Button:hover {
  /* border-color:#808080 */
  /* background-color: #0d0d0d; */
}


.settingsPanel-optionRow-ActionButton:hover {
  /* border-color: #808080; */
  background-color: #242424;
}
.transparentBackgroundHover:hover {
  /* border-color: #808080; */
  background-color: transparent;
}

.settingsPanel-gameMode-Button {
  margin: 10px;
  
}



.settingsPanel-gameMode-Card {
  width: 30px;
  height: 45px;
  border: 2px solid #808080;
  border-radius: 5px;
  background-color: var(--prompt);
}

.optionRowButtonSelected {
  background-color: var(--bg);
  color: var(--promptText);
  /* border-color: var(--fillBorder); */
  /* border:none; */
}

/* .optionRowButtonSelected { */
  /* background-color: var(--bg); */
  /* color: var(--promptText); */
  /* border-color: var(--promptText); */
  /* border:none; */
/* } */

.settingsPanel-gameMode-text {
  font-size: 1rem;
  font-weight: normal;
}




.fmpTextHeader {
  margin:0;
  padding:0.5rem 0.7rem;
  border-radius: 10px;
  /* transform: translate(-50%, 0px); */
  cursor: pointer;
}

.countToken{
  background-color: var(--highlightColor);
  width: 16px;
  height: 16px;
  border-radius: 10px;
  flex-shrink: 0;
  border: 2px solid var(--highlightColor);
  transition: background-color 250ms cubic-bezier(0.18, 0.58, 0.34, 1.0), opacity 400ms cubic-bezier(0.18, 0.58, 0.34, 1.0);
}


.input-unset {
  all: unset;
}

@keyframes voteTextFloatAnimation {
  from {
    opacity: 1;
    transform: translate(0px, -3rem);
  }
  to {
    opacity: 0;
    transform: translate(0px, -10rem);
  }
}

@keyframes resultsScrollHintAnimation {
  0% {
    transform: translate(0px, -20px);
    opacity:0
  }
  75%,100% {
    transform: translate(0px, 0px);
    opacity:1
  }
}

@media (min-width:320px) {
  html{
    font-size:16px
  }
}

@media (min-width:992px) {
  html{
    font-size:16px
  }
}

@media (min-width:2000px) {
  html{
    font-size:18px
  }
}
